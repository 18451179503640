import { createRouter, createWebHashHistory } from "vue-router";
// import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/history_chart",
    component: () => import("../components/history_chart/history_chart.vue"),
  },
  {
    path: "/data_search",
    component: () => import("../components/history_chart/data_search.vue"),
  },
  {
    path: "/season_chart",
    component: () => import("../components/season_chart/season_chart.vue"),
  },
  {
    path: "/forward_curve",
    component: () => import("../components/forward_curve.vue"),
  },

  {
    path: "/formula_comment",
    component: () =>
      import("../components/formula_comment/formula_comment.vue"),
  },
  {
    path: "/formula_comment_edit/:id",
    component: () =>
      import("../components/formula_comment/formula_comment_edit.vue"),
  },
  {
    path: "/view_board/:id",
    component: () => import("../components/history_chart/view_board.vue"),
  },

  {
    path: "/exchange_symbol",
    component: () => import("../components/data_config/exchange_symbol.vue"),
  },
  {
    path: "/script_manager",
    component: () => import("../components/data_config/script_manager.vue"),
  },
  {
    path: "/schema_mgt",
    component: () => import("../components/data_config/schema_mgt.vue"),
  },
  {
    path: "/scheduler_mgt",
    component: () => import("../components/data_config/scheduler_mgt.vue"),
  },

  {
    path: "/weather_file",
    component: () => import("../components/weather_file.vue"),
  },
  {
    path: "/user_mgt",
    component: () => import("../components/base_settings/user_mgt.vue"),
  },
  {
    path: "/article_cate",
    component: () => import("../components/base_settings/article_cate.vue"),
  },
  {
    path: "/article_list",
    component: () => import("../components/article_list/article_list.vue"),
  },
  {
    path: "/article_edit/:id?",
    component: () => import("../components/article_list/article_edit.vue"),
  },
  {
    path: "/article_view/:id?",
    component: () => import("../components/article_list/article_view.vue"),
  },
  {
    path: "/user_edit/:id?",
    component: () => import("../components/base_settings/user_edit.vue"),
  },
  {
    path: "/role_permission/:id",
    component: () => import("../components/base_settings/role_permission.vue"),
  },
  // {
  //   path: "/api_setting",
  //   component: () => import("../components/api_setting.vue"),
  // },
  // {
  //   path: "/base_setting",
  //   component: () => import("../components/base_setting.vue"),
  // },
  // {
  //   path: "/user_edit",
  //   component: () => import("../components/user_edit.vue"),
  // },
  // {
  //   path: "/role",
  //   component: () => import("../components/role.vue"),
  // },
  // {
  //   path: "/role_permission",
  //   component: () => import("../components/role_permission.vue"),
  // },
];

// createWebHistory
// createWebHashHistory
// createMemoryHistory
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
