import ui from "./userinfo.js";

export default [
  {
    name: "Charting",
    key: "menu_spreadchart",
    type: "menu",
    contents: [
      {
        name: "Data Search",
        key: "data_search",
        link: "/data_search",
      },
      {
        name: "History Chart",
        key: "History_chart",
        link: "/history_chart",
      },
      {
        name: "Season Chart",
        key: "season_chart",
        link: "/season_chart",
      },
      {
        name: "Forward Curve",
        key: "forward_curve",
        link: "/forward_curve",
      },

      {
        name: "Formula Comment",
        key: "formula_comment",
        link: "/formula_comment",
      },
    ],
  },

  {
    name: "Data Config",
    key: "data_config_menu_spreadchart",
    type: "menu",
    contents: [
      {
        name: "Script Manager",
        key: "script_manager",
        link: "/script_manager",
      },
      {
        name: "Schema Manager",
        key: "schema_manager",
        link: "/schema_mgt",
      },
      {
        name: "Exchange Symbol",
        key: "exchange_symbol",
        link: "/exchange_symbol",
      },
      {
        name: "Scheduler Manager",
        key: "scheduler_manager",
        link: "/scheduler_mgt",
      },
    ],
  },
  {
    name: "Weather File",
    key: "weather_file",
    type: "link",
    link: "/weather_file",
  },
  {
    name: "Users Management",
    key: "user",
    type: "link",
    link: "/user_mgt",
    is_admin: true,
  },
  {
    name: "Article Cate",
    key: "article_cate",
    type: "link",
    link: "/article_cate",
    is_admin: true,
  },
  {
    name: "Article List",
    key: "article_list",
    type: "link",
    link: "/article_list",
  },
  {
    name: "User Eidt",
    key: "user_edit",
    type: "link",
    link: "/user_edit",
  },
  {
    name: "Exit",
    key: "exit",
    type: "button",
    link: "/",
    click: (callback) => {
      ui.remove();
      callback();
    },
  },
];
