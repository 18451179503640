import { createApp } from "vue";
import App from "./App.vue";

const app = createApp(App);
// router
import router from "./router";
app.use(router);
// AntDesign
import Antd from "ant-design-vue";
import "ant-design-vue/dist/reset.css";
app.use(Antd);

// Axios
import axios from "axios";
import VueAxios from "vue-axios";
import ui from "./userinfo.js";
import { message } from "ant-design-vue";
import XLSX from "xlsx";

let axios_instance = axios.create({
  baseURL: "https://api.tislab.cn",
});
app.use(VueAxios, { axios: axios_instance });
app.axios.defaults.withCredentials = true;
app.axios.interceptors.request.use((req) => {
  if (ui.get()) {
    req.headers = Object.assign(req.headers, {
      "access-token": ui.get().access_token,
    });
  }
  req.headers = Object.assign(req.headers, {
    "local-serial": ui.get_uuid(),
  });
  Object.keys(req.data).forEach((key) => {
    if (!req.data[key]) {
      delete req.data[key];
    }
  });
  delete req.data.lastupdate;
  delete req.data.lasteditor;
  delete req.data.creator;
  delete req.data.is_deleted;
  delete req.data.create;
  delete req.data.is_locked;

  return req;
});
app.axios.interceptors.response.use(
  (r) => {
    if (r.config.method !== "get") {
      if (r.data.status == 1) {
        const url = r.config.url;
        if (
          !url.includes("get") &&
          !url.includes("_r") &&
          !url.includes("status") &&
          !url.includes("data_calc")
        ) {
          message.success(r.data.msg);
        }
        if (url.includes("data_calc") && Object.hasOwn(r.data.data, "result")) {
          r.data.data.result = r.data.data.result.map((rt) => {
            if (rt == null) {
              return null;
            } else {
              return Math.round(rt * 100) / 100;
            }
          });
        }

        if (url.includes("symbols_group_r")) {
          r.data.data.latest.forEach((g) => {
            g.symbols.sort((a, b) => a.code.localeCompare(b.code));
            g.symbols.forEach((s) => {
              s.expire = XLSX.SSF.format("yyyy-mm-dd", s.expire);
            });
          });
        }
        return r;
      } else if (r.data.status == -1) {
        ui.remove();
        return r;
      } else {
        if (r.config.url.includes("test_job_file")) {
          return r;
        }
        message.error(r.data.msg);
        throw new Error(r.data.msg);
      }
    } else {
      return r;
    }
  },
  (error) => {
    message.error(error.message);
  }
);

app.provide("axios", app.config.globalProperties.axios);
app.mount("#app");
