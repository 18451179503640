<template>
  <a-badge :count="errorJobCount">
    <a-button type="primary" @click="drawerVisible = true"> JOBS STATUS </a-button>
  </a-badge>

  <a-drawer
    title="Status and Control"
    :visible="drawerVisible"
    @close="drawerVisible = false"
    @onAfterOpenChange="handleDrawerVisibleChange"
    width="38%"
  >
    <a-input-group compact style="margin: 20px; width: 500px">
      <a-input
        v-model:value="job_filter"
        placeholder="Type to Filter"
        style="width: 350px"
      />
      <a-button type="primary">Search</a-button>
    </a-input-group>

    <a-timeline>
      <a-timeline-item
        v-for="job in filter_jobs"
        :key="job.id"
        :color="getStatusColor(job)"
      >
        <a-card style="margin: 0%; padding: 0%" size="small">
          <h4>{{ job.id }}</h4>
          <p style="font-size: small">
            <b>Next Run Time: </b>{{ formatDate(job.next_run_time) }}
          </p>
          <li v-if="job.status == false" style="color: red">
            <b>Error time: </b> {{ job.error_time }}
          </li>

          <li v-if="job.status == false" style="color: red">
            <b>Error message: </b>{{ job.error_msg }}
          </li>
          <template
            v-if="job.id != 'jobs:ctp_handle.run' && job.id != 'jobs:databento.run'"
          >
            <a-space>
              <a-popconfirm title="Are you sure to run now?" @confirm="runNow(job.id)">
                <a-button type="primary" size="small">Run now</a-button>
              </a-popconfirm>
              <a-popconfirm
                title="Are you sure to pause?"
                @confirm="pauseJob(job.id)"
                v-if="job.next_run_time != undefined"
              >
                <a-button type="default" size="small">Pause</a-button>
              </a-popconfirm>
              <a-popconfirm
                title="Are you sure to resume?"
                @confirm="resumeJob(job.id)"
                v-else
              >
                <a-button type="primary" size="small">Resume</a-button>
              </a-popconfirm>
            </a-space>
          </template>
          <template v-else>
            <a-space>
              <a-popconfirm
                title="Are you sure to start?"
                @confirm="startJob(job.id)"
                v-if="job.status == false"
              >
                <a-button type="primary" size="small">Start</a-button>
              </a-popconfirm>
              <a-popconfirm
                title="Are you sure to stop?"
                @confirm="stopJob(job.id)"
                v-else
              >
                <a-button type="default" size="small">Stop</a-button>
              </a-popconfirm>
              <a-popconfirm
                title="Are you sure to restart?"
                @confirm="restartJob(job.id)"
              >
                <a-button type="default" size="small">Restart</a-button>
              </a-popconfirm>
            </a-space>
          </template>
        </a-card>
      </a-timeline-item>
    </a-timeline>
  </a-drawer>
</template>

<script setup>
import { ref, inject, computed, defineModel } from "vue";
import moment from "moment";
import ui from "../userinfo.js";

const is_login = ref(false);
const MOMENT = moment;
const drawerVisible = defineModel();

const jobs = ref([]);
const job_filter = ref("");

const axios = inject("axios"); // inject axios
const sortedJobs = computed(() => {
  return jobs.value
    .slice()
    .sort((a, b) => new Date(a.next_run_time) - new Date(b.next_run_time));
});

const filter_jobs = computed(() => {
  return jobs.value
    .filter((job) => {
      return job.id.includes(job_filter.value);
    })
    .slice()
    .sort((a, b) => new Date(a.next_run_time) - new Date(b.next_run_time));
});

const handleDrawerVisibleChange = (visible) => {
  drawerVisible.value = visible;
};
const fetchJobData = () => {
  is_login.value = ui.is_login.value;
  if (!is_login.value) {
    return;
  }
  axios.post("/scheduler/get_job_list", {}).then((r) => {
    let jobList = r.data.data;
    axios.post("/scheduler/get_running_jobs", {}).then((r1) => {
      let all_jobs = [];
      r1.data.data.forEach((j) => {
        let func = j.id.split(":")[1].split(".")[1];
        let modoule = j.id.split(":")[1].split(".")[0];
        let job = jobList.find((job) => job.module == modoule && job.func == func);
        if (job) {
          job = { ...job, ...j };
        } else {
          job = j;
        }
        all_jobs.push(job);
      });
      axios.post("/scheduler/job_status", {}).then((r2) => {
        let status = r2.data.data;
        let all_jobs_status = [];
        all_jobs.forEach((j) => {
          let s = status.find((s) => s.id == j.id);
          if (s) {
            j = { ...j, ...s };
          }
          all_jobs_status.push(j);
        });
        jobs.value = all_jobs_status;
      });
    });
  });
};
setInterval(fetchJobData, 5 * 60 * 1000); // 每5分钟发送一次请求
fetchJobData(); // 初始化时立即发送一次请求
const getStatusColor = (job) => {
  if (job.next_run_time == undefined) {
    return "blue";
  } else {
    if (job.status) {
      return "green";
    } else {
      return "red";
    }
  }
};
const runNow = (id) => {
  let d = new MOMENT();
  d.add(60, "s");
  axios.post("/scheduler/modify_job", {
    id: id,
    next_run_time: d.format("YYYY-MM-DD HH:mm:ss"),
  });
};
const pauseJob = (id) => {
  axios.post("/scheduler/pause_job", {
    id: id,
  });
};
const resumeJob = (id) => {
  axios.post("/scheduler/resume_job", {
    id: id,
  });
};
const startJob = (id) => {
  let url = "";
  if (id == "jobs:ctp_handle.run") {
    url = "/scheduler/ctp_start";
    // 添加重启逻辑
  } else if (id == "jobs:databento.run") {
    url = "/scheduler/databento_start";
    // 添加重启逻辑
  }
  if (url == "") {
    return;
  }
  axios.post(url, {}).then((r) => {
    console.log(r);
  });
  // 添加启动逻辑
};
const stopJob = (id) => {
  let url = "";
  if (id == "jobs:ctp_handle.run") {
    url = "/scheduler/ctp_stop";
    // 添加重启逻辑
  } else if (id == "jobs:databento.run") {
    url = "/scheduler/databento_stop";
    // 添加重启逻辑
  }
  if (url == "") {
    return;
  }
  axios.post(url, {}).then((r) => {
    console.log(r);
  });
};
const restartJob = (id) => {
  let url = "";
  if (id == "jobs:ctp_handle.run") {
    url = "/scheduler/ctp_restart";
  } else if (id == "jobs:databento.run") {
    url = "/scheduler/databento_restart";
  }
  if (url == "") {
    return;
  }
  axios.post(url, {}).then((r) => {
    console.log(r);
  });
  // 添加重启逻辑
};
const formatDate = (dateString) => {
  return moment(dateString).format("YYYY-MM-DD HH:mm:ss");
};
const errorJobCount = computed(() => {
  return jobs.value.filter((job) => job.status === false).length;
});
</script>
